import React from 'react'

export default function Facebook() {
  return (
    <svg className='lg:h-[40px] lg:w-[40px] h-[28px] w-[28px] mt-1'  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21_100)" filter="url(#filter0_d_21_100)">
    <path d="M35.2284 15.6142C35.2284 6.99072 28.2377 0 19.6142 0C10.9907 0 4 6.99072 4 15.6142C4 23.4076 9.70987 29.8674 17.1745 31.0388V20.1277H13.21V15.6142H17.1745V12.1742C17.1745 8.2609 19.5057 6.09931 23.0722 6.09931C24.78 6.09931 26.5674 6.40427 26.5674 6.40427V10.2468H24.5986C22.659 10.2468 22.0539 11.4505 22.0539 12.6866V15.6142H26.3845L25.6922 20.1277H22.0539V31.0388C29.5186 29.8674 35.2284 23.4076 35.2284 15.6142Z" fill="#1B1724"/>
    </g>
    <defs>
    <filter id="filter0_d_21_100" x="0" y="0" width="39.2285" height="39.2285" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21_100"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21_100" result="shape"/>
    </filter>
    <clipPath id="clip0_21_100">
    <rect width="31.2284" height="31.2284" fill="white" transform="translate(4)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}
