import React from 'react'

export default function Dribble() {
  return (
    <svg className='lg:h-[31px] lg:w-[33px] h-[22px]  w-[22px]'  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3858 0C7.76577 0 0.771545 6.99423 0.771545 15.6142C0.771545 24.2343 7.76577 31.2284 16.3858 31.2284C24.9888 31.2284 32 24.2343 32 15.6142C32 6.99423 24.9888 0 16.3858 0ZM26.6993 7.19744C28.5621 9.46676 29.6799 12.3627 29.7138 15.4957C29.2735 15.411 24.8702 14.5134 20.4333 15.0723C20.3317 14.8522 20.247 14.615 20.1454 14.378C19.8744 13.7345 19.5696 13.074 19.2647 12.4474C24.1759 10.449 26.4114 7.57002 26.6993 7.19744ZM16.3858 2.30319C19.7728 2.30319 22.8719 3.57333 25.226 5.65635C24.9888 5.99505 22.9735 8.68774 18.2317 10.4659C16.047 6.45229 13.6254 3.16688 13.2528 2.65882C14.2519 2.42173 15.3019 2.30319 16.3858 2.30319ZM10.7125 3.55638C11.0682 4.03057 13.4391 7.33293 15.6576 11.2619C9.42542 12.9216 3.92149 12.8877 3.32876 12.8877C4.19245 8.75548 6.98675 5.31764 10.7125 3.55638ZM3.04086 15.6312C3.04086 15.4957 3.04086 15.3602 3.04086 15.2247C3.61665 15.2416 10.0859 15.3263 16.7414 13.328C17.131 14.0731 17.4866 14.8352 17.8253 15.5972C17.6559 15.6481 17.4696 15.6989 17.3003 15.7497C10.4246 17.9682 6.7666 24.031 6.46176 24.539C4.34486 22.1851 3.04086 19.0521 3.04086 15.6312ZM16.3858 28.9591C13.3035 28.9591 10.4585 27.9092 8.20608 26.1479C8.44317 25.6568 11.1528 20.4408 18.672 17.8158C18.7058 17.7989 18.7228 17.7988 18.7567 17.782C20.6364 22.6423 21.3986 26.7236 21.6018 27.8922C19.9929 28.5865 18.2317 28.9591 16.3858 28.9591ZM23.8203 26.6729C23.6848 25.86 22.9735 21.9649 21.2292 17.1722C25.4123 16.5117 29.0702 17.5957 29.5275 17.7481C28.9517 21.4569 26.8178 24.6576 23.8203 26.6729Z" fill="#1B1724"/>
    </svg>
    
  )
}
