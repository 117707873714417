import React from 'react'

export default function LinkedIn() {
  return (
    <svg className='lg:h-[40px] lg:w-[40px] h-[21px] w-[22px]'  viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21_102)">
    <path d="M29.7387 0H2.36711C1.05593 0 -0.00430298 1.00639 -0.00430298 2.25064V28.9717C-0.00430298 30.216 1.05593 31.2284 2.36711 31.2284H29.7387C31.0499 31.2284 32.1164 30.216 32.1164 28.9778V2.25064C32.1164 1.00639 31.0499 0 29.7387 0ZM9.52525 26.6113H4.75734V11.7046H9.52525V26.6113ZM7.1413 9.6735C5.61054 9.6735 4.37465 8.47194 4.37465 6.9898C4.37465 5.50767 5.61054 4.30611 7.1413 4.30611C8.66577 4.30611 9.90167 5.50767 9.90167 6.9898C9.90167 8.46584 8.66577 9.6735 7.1413 9.6735ZM27.3673 26.6113H22.6056V19.3653C22.6056 17.6392 22.5743 15.4129 20.1276 15.4129C17.6495 15.4129 17.2731 17.2976 17.2731 19.2433V26.6113H12.5177V11.7046H17.0849V13.7417H17.1476C17.7813 12.5707 19.3371 11.3325 21.6521 11.3325C26.4764 11.3325 27.3673 14.4188 27.3673 18.4321V26.6113Z" fill="#1B1724"/>
    </g>
    <defs>
    <clipPath id="clip0_21_102">
    <rect width="32.1207" height="31.2284" fill="white" transform="translate(-0.00430298)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}
